import hoistNonReactStatics from 'hoist-non-react-statics'
import i18n from 'i18next'
import moment from 'moment'
import numeral from 'numeral'
import 'numeral/locales'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { I18nextProvider, initReactI18next, withTranslation } from 'react-i18next'
import Immutable from 'seamless-immutable'

import locales from './locales'


i18n.fallbackLng = 'fr'
i18n.locales = locales

i18n.use(initReactI18next) // bind react-i18next to the instance


i18n.reset = () => i18n.init({
  fallbackLng: i18n.fallbackLng,
  debug: false,
  react: {
    useSuspense: false,
  },

  interpolation: {
    escapeValue: false, // not needed for react!!
    format: (value, format, lng) => {
      if (format === 'uppercase') {
        return value.toUpperCase()
      }
      if (value instanceof Date) {
        return moment(value)
          .format(format)
      }
      if (!Number.isNaN(value) && format) {
        return numeral(value)
          .format(format)
      }
      return value
    },
  },
})

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
  numeral.locale(lng)
})

i18n.reset()

export default i18n

export const localize = () => (Component) => hoistNonReactStatics(withTranslation()(Component), Component)

export class I18nProvider extends PureComponent {

  // eslint-disable-next-line
  static propTypes = {
    i18n: PropTypes.shape({
      services: PropTypes.object,
    }),
    store: PropTypes.shape({
      getState: PropTypes.func,
    }),
    children: PropTypes.node,
  }

  // eslint-disable-next-line
  static defaultProps = {
    i18n: null,
    store: {
      getState: () => ({}),
    },
    children: null,
  }

  constructor(props) {
    super(props)

    if (props.store && props.store.getState() && props.store.getState().i18n) {
      const { lang, store } = props.store.getState().i18n
      const i18nInstance = this.props.i18n || i18n

      if (store && !i18nInstance.initializedStoreOnce) {
        i18nInstance.services.resourceStore.data = Immutable.isImmutable(store)
          ? Immutable.asMutable(store, { deep: true })
          : store
        i18nInstance.initializedStoreOnce = true
      }

      if (lang && !i18nInstance.initializedLanguageOnce) {
        i18nInstance.changeLanguage(lang)
        i18nInstance.initializedLanguageOnce = true
      }
    }
  }

  render() {
    const { children } = this.props
    const i18nInstance = this.props.i18n || i18n

    return (<I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>)
  }

}
